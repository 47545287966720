import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted,onActivated, getCurrentInstance,nextTick} from 'vue';
import ClientUseManualUtil,{IClientUseManualDataObj} from './clientUseManualUtil';
export default defineComponent ({
    name: 'ClientUseManual',
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            document.title = '车辆使用手册';
        })
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IClientUseManualDataObj=reactive<IClientUseManualDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            listData:[],
            listDataBak:[],
            otherParams:{
                queryParam:{
                    filterKey:'',
                    brandId:''
                },
                toolBrandData:[]
            }
        })
        onActivated(()=>{
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientUseManualUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.clientQueryManualCx();
                await dataObj.utilInst.queryManualByType();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.toolBrandData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('brandId'==selectId){
                dataObj.utilInst.filerManual();
            }else if('cx'==selectId){
                if(newValue)await dataObj.utilInst.viewManual(newValue);
            }
        }
        const searchHandler=()=>{
            dataObj.utilInst.filerManual();
        }
        return{
            ...toRefs(dataObj),selectOnChange,searchHandler,comboSelect
        }
    }
});